.App {
  text-align: left;
  padding: 0 10% 5% 10%;
  margin: 0;
}

.App-header {
  font-size: calc(20px + 2vmin);
  text-shadow: #fff;
}

h1 {
  margin: 50px auto 0px;
}

.nav-link {
  padding: 2px;
  text-decoration: none;
  font-weight: bold;
  font-size: calc(11px + 2vmin);
  color: #000;
}

.nav-link-first {
  padding: 2px 2px 2px 0px;
}

.body-link {
  text-decoration: underline;
}

.link-divider {
  display: inline-block;
  font-size: calc(12px + 2vmin);
}

nav {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}

body {
  background-color: #00cbeb;
  font-size: calc(10px + 2vmin);
}

#triangle-topleft {
  width: 0;
  height: 0;
  border-top: 100px solid #eb0056;
  border-right: 100px solid transparent;
}

.project-card {
  border: solid 2px;
  border-radius: 5px;
  border-color: #000;
  padding: 0 5% 5%;
  margin: 30px 0 0 0;
  box-shadow: 2px 2px 4px #0000003a
}

.project-page-image {
  border: solid 2px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #0000003a;
  width: 99%;
}

.project-languages {
  font-style: italic;
}

.cat-picture {
  border: solid 2px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #0000003a;
  width: 99%;
}

.social-icons {
  width: 30px;
  padding: 0px 5px 0px 5px;
  display: inline-block;
}

.social-icons-div {
  float: right;
}

.contact-icons-div {
  display: flex;
  justify-content: left;
}

.contact-icons {
  width: calc(50px + 2vmin);
  display: block;
  text-align: center;
  align-items: center;
  justify-Content: center;
}

a {
  color: #000;
  transition: all 0.3s ease;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
  outline: none;
}

a:active {
  color: #eb0056;
}

.resume {
  float: right;
}

.button-link {
  color: black;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease;
  padding: 5px 18px;
  margin: 0px 5px;
  background-color:#00cbeb;
}

.button-link:hover,
.button-link:focus {
  outline: none;
  text-decoration: none;
  color: #00cbeb;
  background-color: #000;
  border-color: #000;
}

.button-link:active {
  color: #000;
  background-color: #00cbeb;
  text-decoration: none;
}

.project-page-button {
  margin: 0;
}

.cat-generator-button {
  margin-left: 0;
  margin-bottom: 20px;
  display: block;
}

#avatar {
  width: 200px;
  border-radius: 50%;
  margin: 20px 0px 0px 0px;
}

li {
  margin: 0 0 10px 0;
}

.project-links {
  font-weight: bold;
}

@media all and (min-width: 1050px) {
  .App {
    padding: 2% 10% 5% 10%;
  }

  .project-card {
    width: 40%;
    padding: 0 2.5% 2.5%;
    margin: 30px 30px 0 0;
  }

  .project-page-image {
    width: 99%;
  }

  .projects-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

}

@media all and (min-width: 550px) {
  #avatar {
    float: right;
    margin: 0px 0px 30px 30px;
  }

  .project-page-more-info p {
    margin-top: 0;
  }

  .project-page {
    display: inline-block;
    vertical-align: middle;
  }

  .project-page-button {
    margin: 0 0 0 1em;
  }

  .cat-picture {
    width: 60%;
    max-width: 700px;
  }

  .nav-link {
    padding: 20px;
    font-size: calc(12px + 2vmin);
  }

  .nav-link-first {
    padding: 20px 20px 20px 0px;
  }

  .link-divider {
    display: inline-block;
    font-size: calc(12px + 2vmin);
  }

  nav {
    display: inline-block;
    justify-content: flex-start;
  }
}
